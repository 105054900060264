// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/close-email-popup.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home[data-v-cb2c1c9c]{height:100%}.email-capture-popup[data-v-cb2c1c9c]{background:rgba(0,0,0,.7);bottom:0;display:block;height:100%;left:0;position:fixed;right:0;top:0;width:100%;z-index:300000}.emailForm[data-v-cb2c1c9c]{align-items:center;display:flex;height:100%;justify-content:center}input[data-v-cb2c1c9c]{background-color:#efefef;border:none;color:#000;display:block;font-size:14px;font-weight:100;height:30px;letter-spacing:1px;margin:11px auto;max-width:22em;padding:4px 10px;width:100%}#close-email-popup[data-v-cb2c1c9c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:70% auto;cursor:pointer;display:inline-block;height:30px;margin-left:10px;position:relative;top:9px;width:30px}.emailForm>div[data-v-cb2c1c9c]{background:#000;padding:3em}.email-capture-popup h3[data-v-cb2c1c9c]{color:#fff;font-size:16px;line-height:50px;text-transform:uppercase}.email-capture-popup input[type=button][data-v-cb2c1c9c]{background:#8f8f8f;cursor:pointer;font-display:swap;font-family:\"Brandon Grotesque Light\",sans-serif;font-size:16px;height:36px;\n  /*! line-height: 0px; */margin:0 auto;padding:0;text-transform:uppercase}.email-capture-popup input[type=button][data-v-cb2c1c9c],input[type=button][data-v-cb2c1c9c]{color:#000;font-weight:700;letter-spacing:3px;transition:all .3s;width:100%}input[type=button][data-v-cb2c1c9c]{background:#ccc;font-size:14px;padding:1em 37px}input[type=button][data-v-cb2c1c9c]:hover{background-color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
